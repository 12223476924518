

/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f6f9;
  color: #333;
  line-height: 1.6;
}

.container {
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  padding: 30px;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.tagline {
  font-size: 1.1em;
  font-weight: 300;
}

/* General Section Styling */
.section {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.section h2 {
  color: #3f51b5;
  margin-bottom: 15px;
  font-size: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Renovation and Expansion Section Specific Styling */
.expansion-details {
  background-color: #f9f9ff;
  padding: 15px;
  /* border-left: 5px solid #3f51b5; */
  border-radius: 5px;
  margin-top: 10px;
}

.expansion-details ul {
  margin-top: 10px;
  padding-left: 20px;
  list-style: disc;
  line-height: 1.6;
}

.expansion-details ul li {
  margin-bottom: 5px;
}

.expansion-details p {
  margin-top: 15px;
  line-height: 1.6;
}


.bank-details {
  margin-top: 15px;
  padding: 15px;
  background-color: #e3f2fd;
  border-left: 4px solid #3f51b5;
  border-radius: 8px;
}

.footer {
  text-align: center;
  padding: 15px;
  margin-top: 25px;
  color: #666;
  font-size: 0.9em;
}

/* Responsive Styling */
@media (max-width: 600px) {
  .header h1 {
    font-size: 1.6em;
  }
  
  .section h2 {
    font-size: 1.2em;
  }
}


